












import { Component, Vue } from 'vue-property-decorator';
import Heading from '@/components/Heading.vue';
import Main from '@/components/Main.vue';

@Component({
  components: {
    Heading,
    Main,
  },
})
export default class Home extends Vue {
}
