



























import { Component, Vue } from 'vue-property-decorator';
import Heading from '@/components/Heading.vue';
import Main from '@/components/Main.vue';

@Component({
  components: {
    Heading,
    Main,
  },
})
export default class SlaveHelper extends Vue {
  email: string;
  clientId: string;
  clientSecret: string;
  redirectUrl: string;
  autorizeUrl: string;
  insertUrl: string;
  token: string;

  created() {
    this.setUrls();
  }

  data() {
    return {
      email: '',
      redirectUrl: '',
      clientId: '',
      clientSecret: '',
      autorizeUrl: '',
      insertUrl: '',
      token: '',
    }
  }

  setUrls() {
    this.autorizeUrl = `https://accounts.spotify.com/authorize?client_id=${this.clientId}&response_type=code&redirect_uri=${this.redirectUrl}&scope=user-follow-read%20playlist-modify-private%20playlist-read-private&state=MY_STATE`;
    this.insertUrl = `https://spotishine.com/api/slave/insert/${this.email}/${this.clientId}/${this.clientSecret}/${this.redirectUrl.replace(/\//g, '!')}/${this.token}`;
  }
}
