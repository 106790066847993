














import { Component, Vue } from 'vue-property-decorator';
import Heading from '@/components/Heading.vue';
import Main from '@/components/Main.vue';
import LastFound from '@/components/LastFound.vue';

@Component({
  components: {
    Heading,
    LastFound,
    Main,
  },
})
export default class Home extends Vue {
  // preFetch(store: any) {
  //  return new Promise((resolve) => {
  //    store.dispatch('fetchLastReleasesFound').then(resolve);
  //  });
  // }
}
