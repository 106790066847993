
















import { Component, Vue } from 'vue-property-decorator';
import RelativeTime from './RelativeTime.vue';

@Component({
  components: {
    RelativeTime,
  }
})
export default class LastFound extends Vue {
  private lastReleasesFound: {image: string}[];

  data() {
    return {
      lastReleasesFound: this.$store.getters.lastReleasesFound,
    }
  }
}
