import { render, staticRenderFns } from "./Subscribing.vue?vue&type=template&id=08f8f796&scoped=true&"
import script from "./Subscribing.vue?vue&type=script&lang=ts&"
export * from "./Subscribing.vue?vue&type=script&lang=ts&"
import style0 from "./Subscribing.vue?vue&type=style&index=0&id=08f8f796&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f8f796",
  null
  
)

export default component.exports