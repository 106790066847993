let store: any = null;

import Vuex from 'vuex';
import axios from 'axios';

export default () => {
  if(!store) {
    store = new Vuex.Store({
      state: {
        lastReleasesFound: [],
      },
      getters: {
        lastReleasesFound: state => state.lastReleasesFound,
      },
      mutations: {
        lastReleasesFound: (state, releases) => { 
          state.lastReleasesFound = releases;
        }
      },
      actions: {
        fetchLastReleasesFound: context => {
          return axios.get('https://spotishine.com/api/releases/last/8').then(response => {
            context.commit('lastReleasesFound', response.data)
          });
        },
      },
    });
  }
  return store;
}
