








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class Subscribing extends Vue {
  private showButton: boolean;
  private loading: boolean;
  private syncDetails: any;
  private error: boolean;

  data() {
    return {
      syncDetails: false,
      loading: false,
      error: false,
    }
  }

  mounted() {
    if (window.location.href.indexOf('?code=') > -1) {
      let authToken = window.location.href.match(/code=([a-zA-Z0-9_-]*)/)[1]
      this.showButton = false
      this.loading = true
      fetch(`/api/user/insert/${authToken}`)
        .then((response) => {
          this.loading = false
          return response.json()
        })
        .then((response) => {
          this.syncDetails = response
        })
        .catch(() => {
          this.loading = false
          this.error = true
        })
    } else {
      this.error = true
    }
  }
}
