




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RelativeTime extends Vue {
  @Prop() private timestamp!: number;
          private time: string;

  data() {
    return {
      time: this.getTimeString(),
    }
  }

  mounted() {
    setInterval(() => {
      this.time = this.getTimeString();
    }, 1000);
  }

  getTimeString() {
    const minAgo = Math.ceil((Date.now() - new Date(this.timestamp).getTime()) / (1000*60));
    if(minAgo === 1) {
      return `${minAgo} minute`;
    } else if(minAgo < 60) {
      return `${minAgo} minutes`;
    } else {
      const hourAgo = Math.floor(minAgo / 60);
      if(hourAgo === 1) {
        return `${hourAgo} hour`;
      } else {
        return `${hourAgo} hours`;
      }
    }
  }
}
