








































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Heading extends Vue {
  private isBurgerOpened: boolean;

  data() {
    return {
      isBurgerOpened: false,
    }
  }

  onBurgerClick(): void {
    this.isBurgerOpened = !this.isBurgerOpened;
  }
}
