import Router from 'vue-router';
import Home from './pages/Home.vue';
import Settings from './pages/Settings.vue';
import Contact from './pages/Contact.vue';
import Subscribe from './pages/Subscribe.vue';
import KeepMeUpdated from './pages/KeepMeUpdated.vue';
import SlaveHelper from './pages/SlaveHelper.vue';
import Store from './store';

export default () => {
  const router = new Router({
    mode: process.ssr ? 'history' : 'hash',

    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact,
      },
      {
        path: '/subscribe',
        name: 'subscribe',
        component: Subscribe,
      },
      {
        path: '/slave-helper',
        name: 'slave-helper',
        component: SlaveHelper,
      },
      {
        path: '/keep-me-updated',
        name: 'history',
        component: KeepMeUpdated,
      },
      {
        path: '*',
        name: 'home',
        component: Home,
      }
    ]
  });
  router.beforeEach((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    let diffed = false
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c))
    })

    if (!activated.length) {
      return next()
    }

    let preFetchMethods: Promise<any>[] = [];
    activated.map((component) => {
      const methods = (activated[0] as any).options.methods as {preFetch?: Function};
      if(methods && methods.preFetch && process.title != 'browser') {
        preFetchMethods.push(methods.preFetch(Store()));
      }
    });
  
    if(preFetchMethods.length > 0) {
      Promise.all(preFetchMethods).then(() => {
        next();
      });
    } else {
      next();
    }
  });
  return router;
};
