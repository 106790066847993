









import { Component, Vue } from 'vue-property-decorator';
import Heading from '@/components/Heading.vue';
import Main from '@/components/Main.vue';
import Subscribing from '@/components/Subscribing.vue';

@Component({
  components: {
    Heading,
    Main,
    Subscribing,
  },
})
export default class Subscribe extends Vue {}
