<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <Drift />
  </div>
</template>

<script>
import Drift from './components/Drift.vue';
export default {
  components: {
    Drift,
  },
};
</script>

<style lang="scss">
$primary: #84bd00;
@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/base/_all.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma/sass/elements/button.sass';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgb(24, 26, 28);
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}
a:link,
a:visited {
  font-weight: normal;
}
h1, h2 {
  font-weight: 400;
  margin: 0;
  padding: 0;

  a:link,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
}
h1 {
  font-size: 25px;
  position: relative;
  top: -3px;
  font-family: Bree Serif,serif;
}
h2 {
  font-family: Bree Serif,serif;
  font-size: 22px;
  text-align: center;
}
ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inner {
  padding: 0 10px;
  overflow: hidden;
  max-width: 1000px;
  margin: 0px auto;
}
.error {
  color: red;
}
</style>
